// Generated by Framer (a1bdbac)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["xL6WaQqOS", "Vyt2s096l"];

const variantClassNames = {Vyt2s096l: "framer-v-1nroyp4", xL6WaQqOS: "framer-v-11o03zw"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {"Loop Image Wrapper 1 Desktop": "xL6WaQqOS", "Loop Image Wrapper 1 Tablet": "Vyt2s096l"};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; image1?: {src: string; srcSet?: string} }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "xL6WaQqOS", image1: jslSAKH5k, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "xL6WaQqOS", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-V0FpO", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-11o03zw", className)} data-framer-name={"Loop Image Wrapper 1 Desktop"} layoutDependency={layoutDependency} layoutId={"xL6WaQqOS"} ref={ref} style={{borderBottomLeftRadius: 20, borderBottomRightRadius: 20, borderTopLeftRadius: 20, borderTopRightRadius: 20, ...style}} transition={transition} {...addPropertyOverrides({Vyt2s096l: {"data-framer-name": "Loop Image Wrapper 1 Tablet"}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "fill", ...toResponsiveImage(jslSAKH5k)}} className={"framer-1s9tyo0"} data-framer-name={"Loop Image"} layoutDependency={layoutDependency} layoutId={"s2KG3C9QV"} style={{borderBottomLeftRadius: 20, borderBottomRightRadius: 20, borderTopLeftRadius: 20, borderTopRightRadius: 20}} transition={transition}/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-V0FpO [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-V0FpO * { box-sizing: border-box; }", ".framer-V0FpO .framer-105u5mz { display: block; }", ".framer-V0FpO .framer-11o03zw { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 316px; justify-content: center; overflow: hidden; padding: 5px 5px 5px 5px; position: relative; width: 463px; will-change: transform; }", ".framer-V0FpO .framer-1s9tyo0 { flex: none; height: 100%; overflow: hidden; position: relative; width: 100%; will-change: transform; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-V0FpO .framer-11o03zw { gap: 0px; } .framer-V0FpO .framer-11o03zw > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-V0FpO .framer-11o03zw > :first-child { margin-left: 0px; } .framer-V0FpO .framer-11o03zw > :last-child { margin-right: 0px; } }", ".framer-V0FpO.framer-v-1nroyp4 .framer-11o03zw { height: 191px; width: 280px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 316
 * @framerIntrinsicWidth 463
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"Vyt2s096l":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"jslSAKH5k":"image1"}
 */
const FramerF6jOIY5pr: React.ComponentType<Props> = withCSS(Component, css, "framer-V0FpO") as typeof Component;
export default FramerF6jOIY5pr;

FramerF6jOIY5pr.displayName = "Loop Image Wrapper 1";

FramerF6jOIY5pr.defaultProps = {height: 316, width: 463};

addPropertyControls(FramerF6jOIY5pr, {variant: {options: ["xL6WaQqOS", "Vyt2s096l"], optionTitles: ["Loop Image Wrapper 1 Desktop", "Loop Image Wrapper 1 Tablet"], title: "Variant", type: ControlType.Enum}, jslSAKH5k: {title: "Image 1", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerF6jOIY5pr, [])